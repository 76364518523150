<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-row>
                <v-card color="white" width="100%">
                    <v-btn color="info" fixed dark fab bottom right @click="openModule()">
                        <v-icon dark>add</v-icon>
                    </v-btn>
                    <v-col cols="12" lg="12">
                        <data-table
                            :columns="columns"
                            :items="tableData.data"
                            :paginationInfo="tableData.paginatorInfo"
                            @pagination="getPaginationInfo"
                            :showAdvancedFilters="false"
                            :perPage="[10,25,50,100]"
                            :loading="isLoading"
                        >
                            <tbody slot="body" slot-scope="{ data }">
                                <tr :key="item.id" v-for="item in data">
                                    <td>{{item.nombre}}</td>
                                    <td>{{item.email}}</td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small>
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small>
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </data-table>
                    </v-col>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import ComponentOne from './ModalComplementos.vue';
import queries from '@/queries/Usuarios';

export default {
    components: {
        'data-table': Datatable,
    },
    data() {
        return {
            columns         : [
                {
                    label: 'Nombre',
                    name: 'nombre',
                    filterable: true
                },
                {
                    label: 'Email',
                    name: 'email',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            filters         : {},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            }
        }
    },
    methods: {
        openModule() {
            this.$modal.show(ComponentOne, {
                text: 'Modulo Tipo de Nomina'
            }, {
                name: 'usuariosModal',
                height: 'auto',
                width: 900
            }, {
                'before-close': (event) => { alert("Cerraste el modulo Tipo de Nomina, refrescando datatable..."); }
            });
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.isLoading = true;
            this.$apollo.queries.usuarios;
        }
    },
    apollo: {
        usuarios: {
            query       : queries.usersTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : 'nombre'
                }
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.usuarios;
            }
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        margin-top: -6%;
    }
    .titulo {
        font-family: 'Montserrat' !important;
        font-size: 28px !important;
        font-weight: 700 !important;
        color: #0f3043 !important;
        letter-spacing: -0.68px;
        line-height: 34px;
    }
    .btnGuardar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: #183799 !important;
        border-radius: 23px 23px 23px 23px;
        color: white !important;
        width: 70% !important;
        height: 40px !important;
    }
    .btnCerrar {
        font-family: 'Montserrat';
        font-size: 13px !important;
        font-weight: 700 !important;
        background-color: white !important;
        border-radius: 23px 23px 23px 23px;
        color: rgba(121, 141, 152, 0.6);
        width: 70% !important;
        height: 40px !important;
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .tipoLetra700Azul {
        font-weight: 700;
        letter-spacing: -0.34px;
    }
</style>

